import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchClasses(ctx, queryParams) {
      const school = JSON.parse(localStorage.getItem('school'))

      return new Promise((resolve, reject) => {
        axios
          .get(`/school/${school.id}/class`, { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchClass(ctx, { id }) {
      const school = JSON.parse(localStorage.getItem('school'))

      return new Promise((resolve, reject) => {
        axios
          .get(`/school/${school.id}/class/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addClass(ctx, classData) {
      const school = JSON.parse(localStorage.getItem('school'))

      return new Promise((resolve, reject) => {
        axios
          .post(`/school/${school.id}/class`, classData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteClass(ctx, classData) {
      const school = JSON.parse(localStorage.getItem('school'))

      return new Promise((resolve, reject) => {
        axios
          .delete(`/school/${school.id}/class/${classData.id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addUserAsPupil(ctx, { classData, userData }) {
      const school = JSON.parse(localStorage.getItem('school'))

      return new Promise((resolve, reject) => {
        axios
          .post(`/school/${school.id}/class/${classData.id}/pupil/${userData.id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    removeUserAsPupil(ctx, { classData, userData }) {
      const school = JSON.parse(localStorage.getItem('school'))

      return new Promise((resolve, reject) => {
        axios
          .delete(`/school/${school.id}/class/${classData.id}/pupil/${userData.id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addUserAsTeacher(ctx, { classData, userData }) {
      const school = JSON.parse(localStorage.getItem('school'))

      return new Promise((resolve, reject) => {
        axios
          .post(`/school/${school.id}/class/${classData.id}/teacher/${userData.id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    removeUserAsTeacher(ctx, { classData, userData }) {
      const school = JSON.parse(localStorage.getItem('school'))

      return new Promise((resolve, reject) => {
        axios
          .delete(`/school/${school.id}/class/${classData.id}/teacher/${userData.id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
